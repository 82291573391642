import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import theme from '../../styles/theme'
import { media } from '../../utils/media'
import { space } from '../../utils/tokens'

const CardContainer = styled.div `
  /* Rectangle 16: */
  border: 1px solid ${theme.colors.tertiary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-shadow: ${props => props.theme.dropShadow};
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: ${props => props.theme.colors.lightBase};

    img {
      filter: unset;
      transition-property: left opacity;
      transition-duration: 600ms;
      left: calc(50% - 2rem);
    }

    h3, p, span {
      transition-property: color;
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.base};
    }

  }
`

const ServiceCardTitle = styled.span`
  font-size: 5vw;
  padding: ${space[2]}px ${space[3]}px;
  text-align: center;
  z-index: 1;
  font-weight: bold;
  color: ${props => props.theme.colors.black};
  margin-bottom: 0;
  background-color: ${props => props.theme.colors.white}c4;
`

const ServiceCardImg = styled.img`
  height: 75%;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: -1;
  filter: opacity(0.5);
  transition-property: left opacity;
  transition-duration: 300ms;
  pointer-events: none;
  margin-bottom: 0;
`
const ServiceCardDescription = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.base}c2;
  padding: 0 1rem;
  margin-top: 1.5rem;
  flex: 1;
  font-size: 24px;
  text-align: center;
  z-index: 1;

  ${media.tablet`
    padding: 0 2rem;
    margin-top: 2rem;
  `}
`

const CardContent = styled.div`
  flex: 1;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`
const CardButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  z-index: 1;
`

export const ServiceCard = props => {
  
  return (
    <CardContainer css={props.rootCss} hasHover={props.hasHover}>
      <CardContent css={props.layoutCss}>
        <ServiceCardImg src={props.icon} />
        <ServiceCardTitle css={props.titleCss}>{props.title}</ServiceCardTitle>
        {props.description && (
          <ServiceCardDescription>
            <p>{props.description.childMarkdownRemark.excerpt}</p>
          </ServiceCardDescription>
        )}
      </CardContent>
      {props.button && <CardButton>{props.button}</CardButton>}
    </CardContainer>
  )
}

ServiceCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.object,
  button: PropTypes.element,
  hasHover: PropTypes.bool,
}

export const serviceCardFragmentWithDescription = graphql`
  fragment ServiceCardFragment_withDescription on ContentfulService {
    ...ServiceCardFragment
    description {
      childMarkdownRemark {
        html
        excerpt
      }
    }
  }
`

export const serviceCardFragment = graphql`
  fragment ServiceCardFragment on ContentfulService {
    name
    id
    icon {
      file {
        url
      }
    }
    fields {
      path
    }
  }
`

export default ServiceCard