import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from 'styled-components'

import Layout from '../../components/Layout'
import Link from '../../components/Link'
import SEO from '../../components/SEO'

import Container from '../../components/Container'
import { ServiceCard } from "../../components/Card/ServiceCard"

import config from '../../utils/siteConfig'
import { fontSizes, mediaQueries, space, colors } from '../../utils/tokens'
import { MainLayout } from '../../components/MainLayout'

const Services = ({ data }) => {
  const { slug, title } = data.contentfulPage
  const postNode = data.contentfulPage

  const serviceList = data.allContentfulService.edges.map(({ node }) => node)

  return (
      <MainLayout>
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO pagePath={slug} postNode={postNode} />
        <main
          css={css({
            paddingTop: `${space[5]}px`,
          })}
        >
          <section
            css={css({
              padding: `${space[5]}px ${space[4]}px`,
            })}
          >
            <Container>
              <section
                css={css({
                  marginBottom: `${space[5]}px`,
                })}
              >
                <h1 css={css(h2Style)}>FEATURED SERVICES</h1>
                <div
                  css={css({
                    display: `grid`,
                    gridGap: `${space[2]}px`,
                    [mediaQueries.lg]: {
                      gridTemplateColumns: `1fr 1fr 1fr`,
                    },
                  })}
                >
                  {serviceList
                    .filter(service => service.isFeatured)
                    .map((service, id) => {
                      return (
                        <div key={id}>
                          <Link to={service.fields.path}>
                            <div
                              css={css({
                                borderRadius: `4px`,
                                backgroundColor: `${colors.lightBase}`,
                                padding: `${space[4]}px`,
                              })}
                            >
                              <span
                                css={css({
                                  color: `${colors.white}`,
                                  fontSize: fontSizes[3],
                                  display: `block`,
                                  textAlign: `center`,
                                  fontWeight: `bold`,
                                })}
                              >
                                {service.name}
                              </span>
                            </div>
                          </Link>
                        </div>
                      )
                    })}
                </div>
              </section>
              <section css={css(sectionStyle)}>
                <h2 css={css(h2Style)}>A-Z</h2>
                <div
                  css={css({
                    [mediaQueries.lg]: {
                      display: `grid`,
                      gridGap: `${space[4]}px`,
                      gridTemplateColumns: `1fr 1fr 1fr 1fr`,
                    },
                  })}
                >
                  {serviceList.map((service, id) => {
                    return (
                      <div key={id}>
                        <Link to={service.fields.path}>
                          <ServiceCard
                            icon={service.icon.file.url}
                            title={service.name}
                            rootCss={css`
                              min-height: 250px;
                              &:hover {
                                h3,
                                span {
                                  background-color: ${colors.base};
                                }
                              }
                            `}
                            titleCss={css`
                              font-size: 1.5rem;
                              background-color: white;
                              padding: ${space[3]}px 0;
                            `}
                          />
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </section>
            </Container>
          </section>
        </main>
      </MainLayout>
  )
}

const h2Style = {
  textTransform: `capitalize`,
  fontSize: fontSizes[3],
  fontWeight: `bold`,
  marginBottom: `${space[4]}px`,
  opacity: 0.75,
}

const sectionStyle ={
  padding: `${space[4]}px 0`,
}

export default Services

export const servicesQuery = graphql`
         query ServicesQuery {
           contentfulPage(slug: { eq: "services" }) {
             hero {
               ... on Node {
                 ... on ContentfulHeroTextOnly {
                   ...HeroTextOnly
                 }
                 ... on ContentfulHero {
                   ...HeroFragment
                 }
               }
             }
             title
             slug
             metaTitle
             metaDescription {
               internal {
                 content
               }
             }
           }
           allContentfulService(sort: { fields: [name] }) {
             edges {
               node {
                 category
                 isFeatured
                 ...ServiceCardFragment_withDescription
                 fields {
                   path
                 }
               }
             }
           }
         }
       `
